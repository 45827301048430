.video-container {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
}

div.embed-container {
    padding: 56.25% 0 0 0;
    position: relative;
}

div.embed-container iframe {
    position: absolute; 
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1200px) {
    div.embed-container iframe {
        top: 0;
        transform: translate(-50%, 50%) scale(6);
    }
}
