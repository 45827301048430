@media only screen and (max-width: 700px) {
    h1 {
      font-size: 32px;
    }

    div.icon-container a {
        font-size: 32px;
    }
}

@media screen and (min-width: 700px) {
    h1 {
        font-size: 64px;
    }
    div.icon-container a {
        font-size: 64px;
    }
}
  
div.canvas-container {
    width: 100vw;
    height: 500px !important;
}

div.container {
    width: 100%;
    height: 100%;
    color: #ffffff;
    /* text-shadow: 2px 1px 2px rgb(143, 143, 143); */
    display: 'flex';
    justify-content: center;
    align-items: center;
    text-align: center;
}

div.icon-container a {
    text-decoration: none;
    cursor: default;
    color: inherit;
}